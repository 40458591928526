<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <section class="section section-default section-text">
      <div class="container m-container-only-s">
        <div>
          <div class="content">
            <h1 class="title">
              {{ $t("help.title") }}
            </h1>
            <br /><br />
            <h4>
              {{ $t("help.why") }}
            </h4>
            <article class="answer">
              <p>
                {{ $t("help.users") }}
              </p>
              <p>
                {{ $t("help.device") }}
              </p>
              <p>
                {{ $t("help.need") }}
              </p>
              <p>
                {{ $t("help.visit") }}
              </p>
               <p>
                {{ $t("help.demo") }}
               </p>
            </article>
            <br /><br />
            <h4>
              {{ $t("help.was") }}
            </h4>
            <article class="answer">
              {{ $t("help.text") }}
            </article>
            <br /><br />
            <h4>
              {{ $t("help.system") }}
            </h4>
            <br />
            <article class="answer">
              <h4>
                {{ $t("help.apple") }}
              </h4>
              <span class="answer-subtitle"
                >
                {{ $t("help.devices") }}</span
              >
              <br /><br />
              <h4>
                {{ $t("help.android") }}
              </h4>
              <span class="answer-subtitle">
                {{ $t("help.versions") }}
              </span>
              <br />
              <br />
              <h4>
                {{ $t("help.any") }}
              </h4>
              <span>
                {{ $t("help.program") }}
              </span>
            </article>
          </div>
        </div>

      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Help",
  title() {
    return this.$t("pages.help");
  }
};
</script>

<style scoped></style>
